<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              User ID
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formUser.code"
                class="uk-input"
                type="text"
                name="code"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nama User
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formUser.name"
                v-validate="'required|alpha_spaces|min:2|max:30'"
                class="uk-input"
                type="text"
                name="name"
                placeholder="Masukan nama user..."
                :class="{'uk-form-danger': errors.has('name')}"
              >
              <div
                v-show="errors.has('name')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('name') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Jabatan (opsional)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formUser.position"
                v-validate="'alpha_spaces'"
                class="uk-input"
                type="text"
                name="position"
                placeholder="Masukan jabatan..."
                :class="{'uk-form-danger': errors.has('position')}"
              >
              <div
                v-show="errors.has('position')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('position') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Role
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <multiselect
                  id="role"
                  v-model="roleChoose"
                  v-validate="'required'"
                  name="role"
                  track-by="name"
                  label="name"
                  :options="dataRole"
                  :loading="isLoading"
                  :close-on-select="true"
                  :max-height="500"
                  :show-no-results="true"
                  @select="onChangeRole($event)"
                />
                <div
                  v-show="errors.has('role')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('role') }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="isShowFarm"
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Peternakan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <multiselect 
                  id="farm"
                  v-model="farmChoose"
                  label="name"
                  name="peternakan"
                  track-by="farm_code"
                  placeholder="Pilih peternakan..."
                  open-direction="bottom"
                  :options="dataFarms"
                  :searchable="true"
                  :loading="isLoading"
                  :close-on-select="true"
                  :options-limit="10"
                  :max-height="500"
                  :show-no-results="true"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('peternakan')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('peternakan') }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              No. Handphone
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-inline">
                <span class="uk-form-icon">+62 </span>
                <input
                  v-model="formUser.phone"
                  v-validate="'required|numeric|min:9|max:15'"
                  class="uk-input"
                  type="text"
                  name="phone"
                  placeholder="000 0000 0000"
                  :class="{'uk-form-danger': errors.has('phone')}"
                >
              </div>
              <div
                v-show="errors.has('phone')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('phone') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Email
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formUser.email"
                v-validate="'required|email'"
                class="uk-input"
                type="text"
                name="email"
                placeholder="Masukan email..."
                :class="{'uk-form-danger': errors.has('email')}"
              >
              <div
                v-show="errors.has('email')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('email') }}
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Foto KTP
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                class="uk-input"
                name="identity_number"
                placeholder="-"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              No. KTP
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formUser.identity_number"
                v-validate="'required|numeric|min:16|max:16'"
                class="uk-input"
                type="text"
                name="identity_number"
                placeholder="Masukan no ktp..."
                :class="{'uk-form-danger': errors.has('identity_number')}"
                maxlength="16"
              >
              <div
                v-show="errors.has('identity_number')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('identity_number') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Alamat
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <textarea
                v-model="formUser.address"
                v-validate="'required'"
                class="uk-textarea"
                rows="3"
                name="address"
                placeholder="Masukan alamat..."
                :class="{'uk-form-danger': errors.has('address')}"
              />
              <div
                v-show="errors.has('address')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('address') }}
              </div>
            </div>
          </div>
          <div
            v-if="isShowNPWP"
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              NPWP
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formUser.tax_id_number"
                v-mask="formUser.tax_id_number.length < 21 ? '##.###.###.#-###.###' : '###.###.###.#-###.###'"
                v-validate="'min:20|max:21'"
                class="uk-input"
                type="text"
                name="tax_id_number"
                placeholder="Masukan NPWP..."
                :class="{'uk-form-danger': errors.has('tax_id_number')}"
                @change="changeTax"
              >
              <div
                v-show="errors.has('tax_id_number')"
                class="uk-text-small uk-text-danger"
              >
                The tax_id_number field must be at least 15 or 16 characters
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Tanggal Gabung
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <datepicker 
                v-model="datepick"
                v-validate="'required'"
                input-class="uk-input"
                format="DD MMMM YYYY"
                :editable="false"
                name="join_date"
              />
              <div
                v-show="errors.has('join_date')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('join_date') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Status
            </div>
            <div
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl"
              style="display:flex"
            >
              <input
                v-model="statusSwitch"
                class="switcher"
                type="checkbox"
              >
              <span
                v-if="statusSwitch"
                class="uk-text-success label-unbackground"
              >{{ status.ACTIVE }}</span>
              <span
                v-else
                class="uk-text-danger label-unbackground"
              >{{ status.INACTIVE }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-text-right uk-margin-top">
        <button
          class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
          type="button"
          @click="goToBack"
        >
          <img
            v-lazy="`${images}/icon/close.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Batal
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
          type="button"
          @click="showSaveConfirmModal"
        >
          <img
            v-lazy="`${images}/icon/save.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/id'
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateString, dateUnixString } from '@/utils/formater'
import { STATUS } from '@/utils/constant'
export default {
  components: {
    Datepicker
  },
  data() {
    return {
      isShowFarm: false,
      isShowNPWP: false,
      isLoading: false,
      images: PREFIX_IMAGE,
      status: STATUS,
      datepick: new Date(),
      statusSwitch: true,
      formUser: {
        code: dateUnixString(),
        status: 'true',
        role_id: '',
        farm_id: '',
        tax_id_number: ''
      },
      metaFilterRole: {
        status: 'true'
      },
      farmChoose: '',
      dataFarms: [],
      dataRole:[],
      roleChoose:''
    }
  },
  computed: {
    ...mapGetters({
      roles: 'user/roles',
      farms: 'farm/farms',
      metaFarm: 'farm/farm',
      metaRole: 'user/meta'
    })
  },
  watch: {
    async metaFarm() {
      this.isLoading = true
      await this.getFarm({is_all: true})
      this.isLoading = false
      this.dataFarms = this.farms ? this.farms : []
    }
  },
  async mounted() {
    await this.getFarm({is_all: true})
    await this.getRole({is_all: true})
    await this.handleFindFarm()
    this.dataFarms = this.farms ? this.farms : []
    this.dataRole = this.roles ? this.roles : []
  },
  methods: {
    onChangeRole(event) {
      const valRole = event.name
      if(valRole === 'PPL') {
        this.isShowFarm = false
        this.isShowNPWP = false
        this.formUser.tax_id_number = ''
        this.farmChoose = ''
      }else if (valRole === 'Super Admin') {
        this.isShowFarm = false
        this.isShowNPWP = false
        this.formUser.tax_id_number = ''
        this.farmChoose = ''
      }else if (valRole === 'Mitra / Owner' || valRole === 'Anak Kandang') {
        this.isShowFarm = true
        this.isShowNPWP = true
      }else {
        this.isShowFarm = true
        this.isShowNPWP = false
        this.formUser.tax_id_number = ''
      }
    },
    ...mapActions({
      getRole: 'user/getRole',
      getFarm: 'farm/getFarm'
    }),
    ...mapMutations({
      setModalAdd: 'user/SET_MODAL_ADD',
      setMetaFarm: 'farm/SET_FARM'
    }),
    handleFindFarm () {
      this.setMetaFarm({
        ...this.metaFarm
      })
    },
    changeTax (event) {
      this.formUser.tax_id_number = event.target.value.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/g, '$1.$2.$3-$4.$5.$6')
    },
    dateFormatFilter(date) {
      return dateString(date)
    },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.formUser.farm_id = this.farmChoose ? this.farmChoose.id : ''
      this.formUser.role_id = this.roleChoose ? this.roleChoose.id : ''
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.formUser.status = this.statusSwitch ? "true" : "false"
          this.formUser.join_date = this.dateFormatFilter(this.datepick)
          this.setModalAdd(this.formUser)
          window.UIkit.modal('#modal-save-confirm').show()
        }
      })
    }
  }
}
</script>
